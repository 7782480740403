// src/utils/routeUtils.js
import { routesConfig } from "../routesConfig";

export function parseLocationToKeys(pathname) {
  // Find the most specific matching language group
  const group = routesConfig
    .filter((g) => pathname.startsWith(g.basePath))
    .sort((a, b) => b.basePath.length - a.basePath.length)[0];

  if (!group) {
    return {
      lang: "it",
      routeKey: "home",
      categoryKey: null,
      productKey: null,
      pageKey: null,
    };
  }

  let remainder = pathname.slice(group.basePath.length) || "/";
  if (!remainder.startsWith("/")) remainder = "/" + remainder;

  // First check for product matches (they're more specific)
  for (const productKey in group.products) {
    const product = group.products[productKey];
    const productPath = product.path.startsWith("/")
      ? product.path
      : "/" + product.path;

    if (remainder === productPath || remainder.startsWith(productPath + "/")) {
      // Find the category that contains this product
      for (const route of group.routes) {
        if (route.categories) {
          for (const category of route.categories) {
            if (category.products?.includes(productKey)) {
              return {
                lang: group.lang,
                routeKey: route.key,
                categoryKey: category.key,
                productKey: productKey,
                pageKey: null,
              };
            }
          }
        }
      }
    }
  }

  // Then check for categories and pages
  for (const route of group.routes) {
    const routePath = route.path.startsWith("/")
      ? route.path
      : "/" + route.path;

    if (remainder === routePath || remainder.startsWith(routePath + "/")) {
      const result = {
        lang: group.lang,
        routeKey: route.key,
        categoryKey: null,
        productKey: null,
        pageKey: null,
      };

      // Check categories
      if (route.categories) {
        for (const category of route.categories) {
          const categoryPath = category.path.startsWith("/")
            ? category.path
            : "/" + category.path;
          if (
            remainder === categoryPath ||
            remainder.startsWith(categoryPath + "/")
          ) {
            result.categoryKey = category.key;
            break;
          }
        }
      }

      // Check pages
      if (route.pages) {
        for (const page of route.pages) {
          const pagePath = page.path.startsWith("/")
            ? page.path
            : "/" + page.path;
          if (remainder === pagePath || remainder.startsWith(pagePath + "/")) {
            result.pageKey = page.key;
            break;
          }
        }
      }

      return result;
    }
  }

  return {
    lang: group.lang,
    routeKey: "home",
    categoryKey: null,
    productKey: null,
    pageKey: null,
  };
}

export function buildPathForLanguage(
  lang,
  routeKey,
  categoryKey,
  productKey,
  pageKey
) {
  const group = routesConfig.find((g) => g.lang === lang);
  if (!group) return "/";

  // If we have a product key, look for it first
  if (productKey && group.products[productKey]) {
    return `${group.basePath}${group.products[productKey].path}`.replace(
      /\/+/g,
      "/"
    );
  }

  // Find the main route
  const route = group.routes.find((r) => r.key === routeKey);
  if (!route) return group.basePath;

  // If we have a category
  if (categoryKey && route.categories) {
    const category = route.categories.find((c) => c.key === categoryKey);
    if (category) {
      return `${group.basePath}${category.path}`.replace(/\/+/g, "/");
    }
  }

  // If we have a page
  if (pageKey && route.pages) {
    const page = route.pages.find((p) => p.key === pageKey);
    if (page) {
      return `${group.basePath}${page.path}`.replace(/\/+/g, "/");
    }
  }

  // Default to the route path
  return `${group.basePath}${route.path}`.replace(/\/+/g, "/");
}

export function getAllRouteProductPageKeys() {
  const combinations = [];

  for (const group of routesConfig) {
    for (const route of group.routes) {
      combinations.push({
        lang: group.lang,
        routeKey: route.key,
        categoryKey: null,
        productKey: null,
        pageKey: null,
        label: `${group.lang.toUpperCase()} - ${route.key}`,
      });

      if (route.categories) {
        for (const category of route.categories) {
          combinations.push({
            lang: group.lang,
            routeKey: route.key,
            categoryKey: category.key,
            productKey: null,
            pageKey: null,
            label: `${group.lang.toUpperCase()} - ${route.key}.${category.key}`,
          });

          if (category.products) {
            for (const prodKey of category.products) {
              const product = group.products[prodKey];
              if (product) {
                combinations.push({
                  lang: group.lang,
                  routeKey: route.key,
                  categoryKey: category.key,
                  productKey: product.key || prodKey,
                  pageKey: null,
                  label: `${group.lang.toUpperCase()} - ${route.key}.${
                    category.key
                  }.${product.key}`,
                });
              }
            }
          }
        }
      }

      if (route.pages) {
        for (const page of route.pages) {
          combinations.push({
            lang: group.lang,
            routeKey: route.key,
            categoryKey: null,
            productKey: null,
            pageKey: page.key,
            label: `${group.lang.toUpperCase()} - ${route.key}.${page.key}`,
          });
        }
      }
    }
  }

  return combinations;
}
