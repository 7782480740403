import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ParallaxSection from "../components/ParallaxSection";
import ContactForm from "../components/Contact";
import Footer from "../components/Footer";
import VideoSection from "../components/VideoSection";
import FlipCard from "../components/FlipCard";

function Sectors() {
  const { t } = useTranslation("global");
  const [videoReady, setVideoReady] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const videoElement = document.getElementById("background-video");
    if (videoElement) {
      console.log("Video element found!");

      const handleCanPlayThrough = () => {
        console.log("Video is ready to play through!");
        setVideoReady(true);
        setIsLoading(false);
      };
      videoElement.addEventListener("canplaythrough", handleCanPlayThrough);

      return () => {
        console.log("Cleanup: Removing event listener");
        videoElement.removeEventListener(
          "canplaythrough",
          handleCanPlayThrough
        );
      };
    } else {
      console.log("Video element not found!");
    }
  }, []);

  return (
    <div className="main-content transition-opacity duration-500  w-full">
      <section className="flex-1 h-dvh relative">
        {isLoading && (
          <img
            src="../img/videofallback.webp"
            alt="Fallback Image"
            className="absolute inset-0 w-full h-full object-cover"
          />
        )}

        <video
          id="background-video"
          autoPlay
          muted
          loop
          key={"material_video"} // Add this key prop
          className={`absolute inset-0 w-full h-full object-cover transition-opacity duration-500 ${
            videoReady ? "opacity-100" : "opacity-0"
          }`}
        >
          <source
            src="../vid/gerussi_desktop_video_hone.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
        <h1 className="text-4xl lg:text-6xl uppercase w-2/3 absolute left-1/2 -translate-x-1/2 bottom-10 text-white text-center font-bold">
          {t("materiali.h1")}
        </h1>
      </section>

      <div className="grid font-light grid-cols-1 lg:grid-cols-2 gap-4 px-2 md:p-10 lg:p-20 xl:p-40 w-full ">
        <FlipCard
          to="Pillows"
          text={t("petbeds.content.h2")}
          ptext={t("petbeds.content.p")}
          Image="../img/cuscions_pillows.webp"
        />
        <FlipCard
          to="Sofas"
          text={t("pillow.content.h2")}
          ptext={t("pillow.content.p")}
          Image="../img/sofas.webp"
        />
        <FlipCard
          to="Toys"
          text={t("sofas.content.h2")}
          ptext={t("sofas.content.p")}
          Image="../img/peluches.webp"
        />
        <FlipCard
          to="PetBeds"
          text={t("toys.content.h2")}
          ptext={t("toys.content.p")}
          Image="../img/pet_pillows.webp"
        />
      </div>

      <ContactForm />
    </div>
  );
}

export default Sectors;
