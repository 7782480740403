export const loadTranslations = async (language) => {
  const response = await fetch(
    `https://react.agerussi.com/api.php?lang=${language}`
  );

  if (!response.ok) {
    throw new Error("Failed to load translations");
  }

  return response.json();
};

export const saveTranslations = async (language, translations) => {
  const response = await fetch(`https://react.agerussi.com/api.php`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ language, translations }),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.error || "Failed to save translations");
  }

  const data = await response.json();
  return data;
};
