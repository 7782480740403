import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ParallaxSection from "../components/ParallaxSection";
import ContactForm from "../components/Contact";
import Footer from "../components/Footer";
import VideoSection from "../components/VideoSection";

function Green() {
  const { t } = useTranslation("global");
  const [videoReady, setVideoReady] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const videoElement = document.getElementById("background-video");
    if (videoElement) {
      console.log("Video element found!");

      const handleCanPlayThrough = () => {
        console.log("Video is ready to play through!");
        setVideoReady(true);
        setIsLoading(false);
      };
      videoElement.addEventListener("canplaythrough", handleCanPlayThrough);

      return () => {
        console.log("Cleanup: Removing event listener");
        videoElement.removeEventListener(
          "canplaythrough",
          handleCanPlayThrough
        );
      };
    } else {
      console.log("Video element not found!");
    }
  }, []);
  return (
    <div className="main-content transition-opacity duration-500 w-full">
      <div className="h-[95vh] lg:h-screen flex flex-col">
        <section className="flex-1 relative">
          {isLoading && (
            <img
              src="../img/videofallback.webp"
              alt="Fallback Image"
              className="absolute inset-0 w-full h-full object-cover"
            />
          )}

          <video
            id="background-video"
            autoPlay
            muted
            loop
            key={"material_video"} // Add this key prop
            className={`absolute inset-0 w-full h-full object-cover transition-opacity duration-500 ${
              videoReady ? "opacity-100" : "opacity-0"
            }`}
          >
            <source src="../vid/recyclevideo.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </section>
      </div>

      <div className=" bg-[url('/img/rifiuti-tessili-con-mani-sfondo-green.webp')] bg-cover bg-bottom relative h-screen ">
        <div
          className="absolute bottom-4 z-20 h-auto md:h-[40vh] px-4 md:px-40 py-10 w-full 
          bg-[url('/img/feeling-for-feelings-white.png')] bg-no-repeat bg-center bg-6 0% text-center"
        >
          <img
            src="/img/logo-recycle-again-gerussi-300x119.png"
            className="absolute -top-40 left-1/2 -translate-x-1/2"
          />
          <h1 className="text-4xl md:text-5xl font-bold text-[#64b454] mb-3">
            {t("green.h1")}
          </h1>
          <p className="text-sm md:text-base">{t("green.p")}</p>
          <p className="mt-3 text-[#64b454]">
            <strong>{t("green.strong")}</strong>
          </p>
        </div>
      </div>

      {/*   <VideoSection src="../img/PreviewTetto.mp4"></VideoSection> */}
      <div className="h-[80vh]  bg-[url('/img/RifiutiTessili.webp')] bg-cover bg-fixed "></div>

      <div className="bg-[#f4f4f4] py-10 px-4 lg:px-40">
        <div className="mb-20 flex flex-col lg:flex-row">
          <div className="w-full lg:w-2/5">
            <img
              className="mix-blend-multiply"
              src="/img/Riciclo-tessili-SF3A-gerussi-1536x838.webp"
            />
          </div>
          <div className="w-full lg:w-3/5 self-center">
            <h3 className="text-3xl font-bold text-[#64b454] mb-2">
              {t("green.sf3ca")}
            </h3>
            <p className="text-base">{t("green.p1")}</p>
            <a href="/contacts" className="text-gerussiRed underline">
              {t("green.infoLink")}
            </a>
          </div>
        </div>

        <div className="mb-20 flex flex-col lg:flex-row-reverse">
          <div className="w-full lg:w-2/5">
            <img
              className="mix-blend-multiply"
              src="/img/Sminuzzatrice-materiali-espansi-T300-Gerussi.webp"
            />
          </div>
          <div className="w-full lg:w-3/5 self-center">
            <h3 className="text-3xl font-bold text-[#64b454] mb-2">
              {t("green.t300a")}
            </h3>
            <p className="text-base">{t("green.p2")}</p>
            <a href="/contacts" className="text-gerussiRed underline">
              {t("green.infoLink")}
            </a>
          </div>
        </div>

        <div className="mb-20 flex flex-col lg:flex-row">
          <div className="w-full lg:w-2/5">
            <img
              className="mix-blend-multiply"
              src="/img/Infilacuscini-imbottiti-ICO1-Gerussi.webp"
            />
          </div>
          <div className="w-full lg:w-3/5 self-center">
            <h3 className="text-3xl font-bold text-[#64b454] mb-2">
              {t("green.ic2a")}
            </h3>
            <p className="text-base">{t("green.p3")}</p>
            <a href="/contacts" className="text-gerussiRed underline">
              {t("green.infoLink")}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Green;
