import "./index.css";
import App from "./App.js";
import { createRoot } from "react-dom/client";
import "./i18n.js";
import i18next from "./i18n.js";
import { I18nextProvider } from "react-i18next";
import { BrowserRouter as Router } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { NavigationProvider } from "./NavigationContext.js";

const root = createRoot(document.querySelector("#root"));

// Initialize the app
const initApp = () => {
  root.render(
    <I18nextProvider i18n={i18next}>
      <HelmetProvider>
        <Router>
          <NavigationProvider>
            <App />
          </NavigationProvider>
        </Router>
      </HelmetProvider>
    </I18nextProvider>
  );
};

// Start the app
initApp();
