// src/utils/LocalizedNavLink.js
import React, { useMemo } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { routesConfig } from "../routesConfig";

const LocalizedNavLink = ({ to, ...props }) => {
  const { i18n } = useTranslation();
  const location = useLocation();

  // Memoize the current language group, but update when language changes
  const currentLangGroup = useMemo(() => {
    return (
      routesConfig.find((group) => group.lang === i18n.language) ||
      routesConfig.find((group) => group.basePath === "/")
    );
  }, [i18n.language]); // Depend on language

  const basePath = currentLangGroup.basePath;
  const localizedTo = `${basePath}${to}`.replace("//", "/");

  return <NavLink to={localizedTo} {...props} />;
};

export default LocalizedNavLink;
