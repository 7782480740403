import React, { useRef, useLayoutEffect, useEffect } from "react";
import { gsap } from "gsap";
import { SplitText } from "../../gsap-premium/src/SplitText";
import { ScrollTrigger } from "../../gsap-premium/src/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger, SplitText);

const ScrollRevealText = ({ text, className = "", initDelay = 1 }) => {
  const containerRef = useRef(null);
  const splitRef = useRef(null);
  const charsRef = useRef([]);
  const animationsRef = useRef([]);
  const isInitializedRef = useRef(false);

  const cleanup = () => {
    animationsRef.current.forEach((trigger) => trigger?.kill());
    animationsRef.current = [];

    ScrollTrigger.getAll().forEach((st) => st.kill());

    if (splitRef.current) {
      if (Array.isArray(splitRef.current)) {
        splitRef.current.forEach((split) => split.revert());
      } else {
        splitRef.current.revert();
      }
    }
    splitRef.current = null;

    if (containerRef.current) {
      gsap.set(containerRef.current, { clearProps: "all" });
      containerRef.current.textContent = text || "";
    }

    charsRef.current = [];
    isInitializedRef.current = false;
  };

  const initializeAnimation = () => {
    if (!text || !containerRef.current || isInitializedRef.current) return;

    containerRef.current.textContent = text;

    // Create split text for both lines and characters
    splitRef.current = new SplitText(containerRef.current, {
      type: "chars,words,lines",
      linesClass: "reveal-line",
      wordsClass: "reveal-word",
      charsClass: "reveal-char",
      reduceWhiteSpace: false,
    });

    const chars = splitRef.current.chars;
    const lines = splitRef.current.lines;
    charsRef.current = chars;

    // Set initial states
    gsap.set(lines, {
      y: 5,
      opacity: 0,
    });

    gsap.set(chars, {
      opacity: 0.2,
    });

    // Create scroll trigger for line-by-line reveal
    const linesTrigger = ScrollTrigger.create({
      trigger: containerRef.current,
      start: "top bottom-=100",
      once: true, // Only trigger once
      onEnter: () => {
        gsap.to(lines, {
          y: 0,
          opacity: 1,
          duration: 0.8,
          stagger: {
            amount: 0.3,
            ease: "power2.out",
          },
          ease: "power2.out",
        });
      },
    });

    // Create scroll trigger for character reveal
    const charsTrigger = ScrollTrigger.create({
      trigger: containerRef.current,
      start: "top center+=100",
      end: "bottom center",
      onUpdate: (self) => {
        const progress = self.progress;
        const charsToReveal = Math.floor(chars.length * progress);

        chars.forEach((char, index) => {
          if (index < charsToReveal) {
            gsap.to(char, {
              opacity: 1,
              duration: 0.2,
              ease: "none",
            });
          } else {
            gsap.to(char, {
              opacity: 0.2,
              duration: 0.2,
              ease: "none",
            });
          }
        });
      },
    });

    animationsRef.current = [linesTrigger, charsTrigger];
    isInitializedRef.current = true;
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      initializeAnimation();
    }, initDelay * 1000);

    return () => {
      clearTimeout(timer);
      cleanup();
    };
  }, [text, initDelay]);

  useLayoutEffect(() => {
    if (!isInitializedRef.current) return;

    const resizeObserver = new ResizeObserver(() => {
      cleanup();
      initializeAnimation();
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [text]);

  return <div ref={containerRef} className={className} />;
};

export default ScrollRevealText;
