// src/pages/products/ProductTemplate.jsx
import React from "react";
import { useTranslation } from "react-i18next";
import ContactForm from "../../components/Contact";
import LocalizedNavLink from "../../utils/LocalizedNavLink";
import getPathByKey from "../../utils/getPathByKey ";

const ProductTemplate = ({
  title,
  subtitle,
  description,
  features = [],
  tecnicals = [],
  directives,
  img1Src,
  img1Alt,
  img2Src,
  img2Alt,
  options = [],
  warranty,
  specs = { headers: [], values: [] },
  relatedProducts = [],
}) => {
  const { t, ready, i18n } = useTranslation("global");

  // Add a loading state that checks both i18n ready state and language loaded state
  const isLoading = !ready || !i18n.isInitialized;

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gerussiRed"></div>
      </div>
    );
  }

  // Ensure arrays are actually arrays before mapping
  const featuresList = Array.isArray(features) ? features : [];
  const technicalsList = Array.isArray(tecnicals) ? tecnicals : [];
  const optionsList = Array.isArray(options) ? options : [];
  const relatedProductsList = Array.isArray(relatedProducts)
    ? relatedProducts
    : [];

  return (
    <>
      <div className="main-content transition-opacity bg-white duration-500 w-full pt-20 px-4 md:px-4 lg:px-10 2xl:px-40">
        {/* First Section */}
        <div className="flex flex-col md:flex-row items-center mb-10">
          <div className="md:w-1/2">
            <img src={img1Src} alt={img1Alt} className="w-full h-auto p-10" />
          </div>
          <div className="md:w-1/2 md:pl-8">
            <h1 className="text-4xl lg:text-6xl text-gerussiRed font-light mb-2">
              {t(title)}
            </h1>
            <h2 className="text-2xl font-bold text-enoDark mb-4">
              {t(subtitle)}
            </h2>
            <p className="mb-6">{t(description)}</p>
            <div className="flex space-x-2">
              {featuresList.map((featKey, index) => (
                <div
                  key={index}
                  className="h-16 px-4 bg-gray-300 flex items-center justify-center"
                >
                  <span>{t(featKey)}</span>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Second Section */}
        <div className="flex flex-col md:flex-row items-center mb-10">
          <div className="md:w-1/2 md:pr-8">
            <h2 className="text-3xl font-bold mb-4">{t("labels.tecnicals")}</h2>
            <ul className="list-disc pl-5">
              {technicalsList.map((tecKey, index) => (
                <li key={index}>{t(tecKey)}</li>
              ))}
            </ul>
            <p className="mt-4 underline">{t(directives)}</p>
          </div>
          <div className="md:w-1/2">
            <img src={img2Src} alt={img2Alt} className="w-full h-auto" />
          </div>
        </div>

        {/* Third Section */}
        <div className="flex flex-col md:flex-row items-start mb-2">
          {optionsList.length === 0 ? null : (
            <div className="md:w-1/2 md:pr-8">
              <h3 className="text-2xl font-bold mb-2">
                {t("labels.optionsOnRequest")}
              </h3>
              <ul className="list-disc pl-5">
                {optionsList.map((optKey, index) => (
                  <li key={index}>{t(optKey)}</li>
                ))}
              </ul>
            </div>
          )}
          <div className="md:w-1/2 md:pl-4">
            <h3 className="text-2xl font-bold mb-2">{t("labels.warranty")}</h3>
            <p>{t(warranty)}</p>
          </div>
        </div>

        {/* Table Section */}
        <div className="overflow-x-auto mb-20">
          <table className="min-w-full text-left">
            <thead>
              <tr className="bg-gray-800 text-white">
                {specs.headers.map((headerKey, index) => (
                  <th key={index} className="py-2 px-4 border-b">
                    {t(headerKey)}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="bg-gray-200">
              <tr>
                {specs.values.map((valueKey, index) => (
                  <td key={index} className="py-2 px-4 border-b">
                    {t(valueKey)}
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </div>

        {/* Related Products */}
        <div className="my-10 lg:my-12 xl:my-52 px-4 lg:px-10 xl:px-40">
          <h3 className="text-3xl font-bold mb-4">
            {t("labels.relatedProducts")}
          </h3>
          <div className="flex flex-col md:flex-row">
            {relatedProductsList.map((product, index) => (
              <div
                key={index}
                className="w-full md:w-1/2 mb-4 border transition-shadow duration-500 ease-in-out hover:shadow-xl"
              >
                <LocalizedNavLink to={getPathByKey(product.title)}>
                  <img src={product.img} alt="" />
                  <div className="p-4">
                    <h3 className="text-4xl text-gerussiRed font-bold mt-2">
                      {t(product.title)}
                    </h3>
                    <p>{t(product.description)}</p>
                  </div>
                </LocalizedNavLink>
              </div>
            ))}
          </div>
        </div>
      </div>

      <ContactForm />
    </>
  );
};

export default ProductTemplate;
