// src/utils/translationUtils.js

export const flattenTranslations = (obj, parentKey = "", separator = ".") => {
  const flattened = Object.keys(obj).reduce((acc, key) => {
    const newKey = parentKey ? `${parentKey}${separator}${key}` : key;
    if (typeof obj[key] === "object" && obj[key] !== null) {
      Object.assign(acc, flattenTranslations(obj[key], newKey, separator));
    } else {
      acc[newKey] = obj[key];
    }
    return acc;
  }, {});
  /*   console.log("Traduzioni appiattite:", flattened); // Log per debug */
  return flattened;
};

export const unflattenTranslations = (obj, separator = ".") => {
  const result = {};
  Object.keys(obj).forEach((flatKey) => {
    const keys = flatKey.split(separator);
    keys.reduce((acc, key, index) => {
      if (index === keys.length - 1) {
        acc[key] = obj[flatKey];
      } else {
        if (!acc[key]) acc[key] = {};
      }
      return acc[key];
    }, result);
  });
  console.log("Traduzioni ricostruite:", result); // Log per debug
  return result;
};
