// src/App.js
import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faMultiply,
  faChevronRight,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

import { useTranslation } from "react-i18next";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";

import LoadingScreen from "./components/LoadingScreen";
import LanguageSelector from "./components/language-selector";
import Footer from "./components/Footer";
import { SEO } from "./components/LangSeo";
import { routesConfig } from "./routesConfig";
import LocalizedNavLink from "./utils/LocalizedNavLink";
import { loadTranslations } from "./components/translations";
import TranslationEditor from "./components/TranslationEditor";
import { NavigationProvider } from "./NavigationContext";

// Create a context so that TranslationEditor can access isEditMode/setIsEditMode.
// ----------------------------------------------------------------------------
// NavBar Component (Desktop & Mobile)
// ----------------------------------------------------------------------------
const NavBar = memo(({ isMenuOpen, toggleMenu, closeMenu }) => {
  const { t } = useTranslation("global");
  const location = useLocation();
  const navRef = useRef(null);
  const prevScrollPos = useRef(window.scrollY);
  const [isAtTop, setIsAtTop] = useState(true);
  const [isPopupVisible, setIsPopupVisible] = useState({
    lavorazioni: false,
    sectors: false,
    materiali: false,
    contact: false,
  });

  // Hide/show navbar on scroll
  useEffect(() => {
    const navElement = navRef.current;
    const hiddenClass = "-translate-y-full";
    const visibleClass = "translate-y-0";

    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      const isScrollingDown = currentScrollPos > prevScrollPos.current;
      setIsAtTop(currentScrollPos === 0);
      if (navElement) {
        if (isScrollingDown && currentScrollPos > 50) {
          navElement.classList.remove(visibleClass);
          navElement.classList.add(hiddenClass);
        } else {
          navElement.classList.remove(hiddenClass);
          navElement.classList.add(visibleClass);
        }
      }
      prevScrollPos.current = currentScrollPos;
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Popup toggle functions for dropdowns in mobile menu
  const togglePopup = (popupName) => {
    setIsPopupVisible((prev) => ({
      ...prev,
      [popupName]: !prev[popupName],
    }));
  };
  const showPopup = (popupName) => {
    setIsPopupVisible((prev) => ({ ...prev, [popupName]: true }));
  };
  const hidePopup = (popupName) => {
    setIsPopupVisible((prev) => ({ ...prev, [popupName]: false }));
  };
  const closeMenuAndResetPopups = () => {
    closeMenu();
    setIsPopupVisible({
      lavorazioni: false,
      sectors: false,
      materiali: false,
      contact: false,
    });
  };

  // Determine current language group
  const currentLangGroup =
    routesConfig.find(
      (group) => group.basePath && location.pathname.startsWith(group.basePath)
    ) || routesConfig.find((group) => group.basePath === "/");

  // Normalize pathname for comparison
  const getNormalizedPathname = (pathname, basePath) => {
    if (basePath === "/") return pathname;
    if (pathname.startsWith(basePath)) {
      const normalizedPath = pathname.slice(basePath.length);
      return normalizedPath.startsWith("/")
        ? normalizedPath
        : "/" + normalizedPath;
    }
    return pathname;
  };
  const normalizedPathname = getNormalizedPathname(
    location.pathname,
    currentLangGroup?.basePath || "/"
  );

  // Prevent background scroll when menu is open
  useEffect(() => {
    document.body.style.overflow = isMenuOpen ? "hidden" : "";
  }, [isMenuOpen]);

  return (
    <nav
      ref={navRef}
      className={`items-center p-4 h-auto fixed w-full z-30 transition-transform duration-300 transform ${
        isAtTop ? "bg-transparent" : "bg-white shadow-md"
      }`}
    >
      <div className="flex justify-between items-center">
        {/* Mobile Menu Button */}
        <button onClick={toggleMenu} className="block lg:hidden z-50">
          <FontAwesomeIcon icon={isMenuOpen ? faMultiply : faBars} size="lg" />
        </button>

        {/* Mobile Menu */}
        <div
          className={`fixed inset-0 z-40 transform w-5/6 lg:hidden h-screen ${
            isMenuOpen ? "translate-x-0" : "-translate-x-full"
          } transition-transform duration-300 ease-in-out bg-gray-900 bg-opacity-95`}
        >
          <div className="h-full overflow-y-auto">
            <ul className="flex flex-col text-xl pt-16 px-4 pb-4 text-white">
              {/* Logo */}
              <li className="mb-4">
                <LocalizedNavLink
                  to="/"
                  className="text-3xl cursor-pointer flex items-center"
                  onClick={closeMenuAndResetPopups}
                  end
                >
                  <img
                    className="w-20 animate-pulse"
                    src="/img/logo-gerussi.png"
                    alt="Gerussi Logo"
                  />
                </LocalizedNavLink>
              </li>

              {/* Menu Items */}
              {currentLangGroup?.routes?.map(
                (route) =>
                  route.showInNav && (
                    <li key={route.path}>
                      <div className="flex items-center justify-between py-1">
                        <LocalizedNavLink
                          to={route.path}
                          className={({ isActive }) =>
                            (route.path === "/" &&
                              normalizedPathname === "/") ||
                            (route.path !== "/" &&
                              normalizedPathname.startsWith(
                                route.path === "/" ? "/" : route.path
                              ))
                              ? "text-gerussiRed font-semibold"
                              : "hover:text-gerussiRed transition-colors duration-300"
                          }
                          onClick={closeMenuAndResetPopups}
                          end={route.path === "/"}
                        >
                          {t(route.nameKey)}
                        </LocalizedNavLink>
                        {(route.categories || route.pages) && (
                          <button
                            className="cursor-pointer focus:outline-none"
                            onClick={() => togglePopup(route.key)}
                          >
                            <FontAwesomeIcon
                              icon={
                                isPopupVisible[route.key]
                                  ? faChevronDown
                                  : faChevronRight
                              }
                            />
                          </button>
                        )}
                      </div>

                      {/* Mobile Dropdown for Submenus */}
                      <AnimatePresence>
                        {isPopupVisible[route.key] && (
                          <motion.ul
                            initial={{ height: 0, opacity: 0 }}
                            animate={{ height: "auto", opacity: 1 }}
                            exit={{ height: 0, opacity: 0 }}
                            transition={{ duration: 0.3 }}
                            className="pl-4 border-l border-gray-600 overflow-hidden"
                          >
                            {route.categories?.map((category) => (
                              <li key={category.path}>
                                <div className="flex items-center justify-between py-1">
                                  <LocalizedNavLink
                                    to={category.path}
                                    className={({ isActive }) =>
                                      normalizedPathname.startsWith(
                                        category.path
                                      )
                                        ? "text-gerussiRed font-semibold"
                                        : "hover:text-gerussiRed transition-colors duration-300"
                                    }
                                    onClick={closeMenuAndResetPopups}
                                  >
                                    {t(category.nameKey)}
                                  </LocalizedNavLink>
                                  {category.products?.length > 0 && (
                                    <button
                                      className="cursor-pointer focus:outline-none"
                                      onClick={() => togglePopup(category.key)}
                                    >
                                      <FontAwesomeIcon
                                        icon={
                                          isPopupVisible[category.key]
                                            ? faChevronDown
                                            : faChevronRight
                                        }
                                      />
                                    </button>
                                  )}
                                </div>

                                {/* Nested Dropdown for Products */}
                                <AnimatePresence>
                                  {isPopupVisible[category.key] &&
                                    category.products?.length > 0 && (
                                      <motion.ul
                                        initial={{
                                          height: 0,
                                          opacity: 0,
                                        }}
                                        animate={{
                                          height: "auto",
                                          opacity: 1,
                                        }}
                                        exit={{
                                          height: 0,
                                          opacity: 0,
                                        }}
                                        transition={{ duration: 0.3 }}
                                        className="pl-4 border-l border-gray-500 overflow-hidden"
                                      >
                                        {category.products.map((productKey) => {
                                          const product =
                                            currentLangGroup.products[
                                              productKey
                                            ];
                                          if (!product) return null;
                                          return (
                                            <li
                                              key={product.path}
                                              className="py-1"
                                            >
                                              <LocalizedNavLink
                                                to={product.path}
                                                className={({ isActive }) =>
                                                  normalizedPathname.startsWith(
                                                    product.path
                                                  )
                                                    ? "text-gerussiRed"
                                                    : "hover:text-gerussiRed transition-colors duration-300"
                                                }
                                                onClick={
                                                  closeMenuAndResetPopups
                                                }
                                              >
                                                {t(product.displayNameKey)}
                                              </LocalizedNavLink>
                                            </li>
                                          );
                                        })}
                                      </motion.ul>
                                    )}
                                </AnimatePresence>
                              </li>
                            ))}

                            {route.pages?.map((page) => (
                              <li key={page.path}>
                                <div className="py-1">
                                  <LocalizedNavLink
                                    to={page.path}
                                    className={({ isActive }) =>
                                      normalizedPathname.startsWith(page.path)
                                        ? "text-gerussiRed font-semibold"
                                        : "hover:text-gerussiRed transition-colors duration-300"
                                    }
                                    onClick={closeMenuAndResetPopups}
                                  >
                                    {t(page.nameKey)}
                                  </LocalizedNavLink>
                                </div>
                              </li>
                            ))}
                          </motion.ul>
                        )}
                      </AnimatePresence>
                    </li>
                  )
              )}
            </ul>
          </div>
        </div>

        {/* Desktop Menu */}
        <div className="hidden lg:flex items-center w-full">
          <LocalizedNavLink to="/" className="text-xl px-2 cursor-pointer" end>
            <img
              className="w-20 animate-pulse"
              src="/img/logo-gerussi.png"
              alt="Gerussi Logo"
            />
          </LocalizedNavLink>
          <ul className="flex items-center space-x-4 ml-10">
            {currentLangGroup?.routes?.map(
              (route) =>
                route.showInNav && (
                  <li
                    key={route.path}
                    className="relative flex items-center"
                    onMouseEnter={() => showPopup(route.key)}
                    onMouseLeave={() => hidePopup(route.key)}
                  >
                    <LocalizedNavLink
                      to={route.path}
                      className={({ isActive }) =>
                        (route.path === "/" && normalizedPathname === "/") ||
                        (route.path !== "/" &&
                          normalizedPathname.startsWith(
                            route.path === "/" ? "/" : route.path
                          ))
                          ? "text-gerussiRed font-semibold"
                          : "hover:text-gerussiRed transition-colors duration-300"
                      }
                      end={route.path === "/"}
                    >
                      {t(route.nameKey)}
                    </LocalizedNavLink>

                    {/* Desktop Dropdown */}
                    {((route.key === "lavorazioni" && route.categories) ||
                      (["sectors", "materiali", "contact"].includes(
                        route.key
                      ) &&
                        route.pages)) && (
                      <div
                        className={`absolute top-full ${
                          route.key === "lavorazioni"
                            ? "w-[600px] left-0 right-0 mx-auto"
                            : "left-0 w-48"
                        } bg-gray-800 text-white shadow-lg p-4 rounded-lg z-50 duration-300 ${
                          isPopupVisible[route.key]
                            ? "opacity-100 translate-y-0 pointer-events-auto"
                            : "opacity-0 -translate-y-2 pointer-events-none"
                        }`}
                      >
                        {route.key === "lavorazioni" && (
                          <div className="p-2 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                            {route.categories.map((category) => (
                              <div
                                key={category.path}
                                className="flex flex-col"
                              >
                                <LocalizedNavLink
                                  to={category.path}
                                  className="mb-2 text-lg font-semibold hover:text-gerussiRed transition-colors duration-300"
                                  onClick={() => hidePopup(route.key)}
                                >
                                  {t(category.nameKey)}
                                </LocalizedNavLink>
                                {category.products?.length > 0 && (
                                  <ul className="pl-2">
                                    {category.products.map((productKey) => {
                                      const product =
                                        currentLangGroup.products[productKey];
                                      if (!product) return null;
                                      return (
                                        <li key={product.path} className="py-1">
                                          <LocalizedNavLink
                                            to={product.path}
                                            className="text-sm hover:text-gerussiRed transition-colors duration-300"
                                            onClick={() => hidePopup(route.key)}
                                          >
                                            {t(product.displayNameKey)}
                                          </LocalizedNavLink>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                )}
                              </div>
                            ))}
                          </div>
                        )}
                        {(route.key === "sectors" ||
                          route.key === "materiali" ||
                          route.key === "contact") &&
                          route.pages && (
                            <ul>
                              {route.pages.map((page) => (
                                <li key={page.path} className="mb-2">
                                  <LocalizedNavLink
                                    to={page.path}
                                    className="hover:text-gerussiRed transition-colors duration-300"
                                    onClick={() => hidePopup(route.key)}
                                  >
                                    {t(page.nameKey)}
                                  </LocalizedNavLink>
                                </li>
                              ))}
                            </ul>
                          )}
                      </div>
                    )}
                  </li>
                )
            )}
          </ul>
        </div>

        {/* Language Selector */}
        <div className="flex items-center">
          <LanguageSelector />
        </div>
      </div>
    </nav>
  );
});

// ----------------------------------------------------------------------------
// Main App Component
// ----------------------------------------------------------------------------
export const AppContext = React.createContext({});

const getAllRoutes = (routesConfig) => {
  const allRoutes = [];
  routesConfig.forEach((group) => {
    const { basePath, routes, products } = group;
    routes.forEach((route) => {
      const fullPath = `${basePath}${route.path}`.replace("//", "/");
      allRoutes.push({
        path: fullPath,
        component: route.component,
        key: route.key,
      });
      if (route.categories) {
        route.categories.forEach((category) => {
          const categoryFullPath = `${basePath}${category.path}`.replace(
            "//",
            "/"
          );
          allRoutes.push({
            path: categoryFullPath,
            component: category.component,
            key: category.key,
          });
          if (category.products && category.products.length > 0) {
            category.products.forEach((productKey) => {
              const productRoute = products[productKey];
              if (productRoute) {
                const productFullPath =
                  `${basePath}${productRoute.path}`.replace("//", "/");
                allRoutes.push({
                  path: productFullPath,
                  component: productRoute.component,
                  key: productRoute.key || productKey,
                });
              }
            });
          }
        });
      }
      if (route.pages) {
        route.pages.forEach((page) => {
          const pageFullPath = `${basePath}${page.path}`.replace("//", "/");
          allRoutes.push({
            path: pageFullPath,
            component: page.component,
            key: page.key,
          });
        });
      }
    });
  });
  return allRoutes;
};

const redDivTransitionIn = {
  duration: 0.3,
  ease: "easeIn",
};

const redDivTransitionOut = {
  duration: 0.4,
  ease: "easeOut",
};

const totalRedDivDuration =
  redDivTransitionIn.duration + redDivTransitionOut.duration;

const pageTransition = {
  duration: 0.1,
  ease: "easeOut",
  delay: redDivTransitionIn.duration,
};

const redDivVariants = {
  initial: { y: "100%" },
  animate: { y: 0, transition: redDivTransitionIn },
  exit: { y: "-100%", transition: redDivTransitionOut },
};

const pageVariants = {
  initial: { opacity: 0, y: "100%" },
  in: { opacity: 1, y: 0 },
  out: { opacity: 1, y: 0 },
};

function App() {
  const { i18n } = useTranslation();
  const [isLoading] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();
  const [isTransitioning, setIsTransitioning] = useState(false);
  const pageRef = useRef(null);
  const [currentLangGroup, setCurrentLangGroup] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);

  useEffect(() => {
    const handleHashChange = () => {
      setIsEditMode(window.location.hash === "#translate");
    };
    handleHashChange();
    window.addEventListener("hashchange", handleHashChange);
    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);

  useEffect(() => {
    const path = location.pathname;
    const matchingGroups = routesConfig.filter(
      (group) => group.basePath && path.startsWith(group.basePath)
    );
    const newLangGroup =
      matchingGroups.sort((a, b) => b.basePath.length - a.basePath.length)[0] ||
      routesConfig.find((group) => group.basePath === "/");

    setCurrentLangGroup(newLangGroup);

    const lang = newLangGroup ? newLangGroup.lang : "it";
    if (lang && !i18n.language.startsWith(lang)) {
      i18n.changeLanguage(lang);
    }
  }, [location.pathname, i18n]);

  useEffect(() => {
    const load = async () => {
      try {
        const translations = await loadTranslations(i18n.language);
        i18n.addResourceBundle(
          i18n.language,
          "global",
          translations,
          true,
          true
        );
      } catch (error) {
        console.error("Error loading translations:", error);
      }
    };
    load();
  }, [i18n.language, i18n]);

  useEffect(() => {
    if (location.state?.languageChange) {
      setIsTransitioning(false);
      return;
    }

    setIsTransitioning(true);
    const scrollResetTimer = setTimeout(() => {
      window.scrollTo(0, 0);
    }, redDivTransitionIn.duration * 1000);

    const transitionEndTimer = setTimeout(() => {
      setIsTransitioning(false);
    }, totalRedDivDuration * 1500);

    return () => {
      clearTimeout(scrollResetTimer);
      clearTimeout(transitionEndTimer);
    };
  }, [location.pathname, location.state]);

  useEffect(() => {
    document.body.style.overflow = isTransitioning ? "hidden" : "";
  }, [isTransitioning]);

  const toggleMenu = useCallback(() => setIsMenuOpen((prev) => !prev), []);
  const closeMenu = useCallback(() => setIsMenuOpen(false), []);

  const allRoutes = getAllRoutes(routesConfig);

  return (
    <NavigationProvider>
      <AppContext.Provider
        value={{ isEditMode, setIsEditMode, currentLangGroup }}
      >
        <NavBar
          isMenuOpen={isMenuOpen}
          toggleMenu={toggleMenu}
          closeMenu={closeMenu}
        />
        {isMenuOpen && (
          <div
            className="fixed inset-0 bg-black bg-opacity-50 z-10"
            onClick={closeMenu}
          ></div>
        )}
        <LoadingScreen isVisible={isLoading} />
        <div className="min-h-screen overflow-x-hidden" ref={pageRef}>
          <AnimatePresence mode="wait">
            <motion.div
              key={location.pathname}
              initial={location.state?.languageChange ? false : "initial"}
              animate={location.state?.languageChange ? false : "in"}
              exit={location.state?.languageChange ? false : "out"}
              variants={pageVariants}
              transition={pageTransition}
              style={{
                position: "absolute",
                width: "100%",
                minHeight: "100%",
                top: 0,
                left: 0,
              }}
            >
              <Routes location={location}>
                {allRoutes.map((route) => (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={
                      <>
                        <SEO page={route.key} />
                        <route.component />
                        <Footer />
                      </>
                    }
                  />
                ))}
                <Route
                  path="*"
                  element={
                    <Navigate
                      to={`${currentLangGroup?.basePath || "/"}`}
                      replace
                    />
                  }
                />
              </Routes>
            </motion.div>
          </AnimatePresence>
          <AnimatePresence>
            {isTransitioning && !location.state?.languageChange && (
              <motion.div
                key="red-div"
                variants={redDivVariants}
                initial="initial"
                animate="animate"
                exit="exit"
                style={{
                  position: "fixed",
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#B0151D",
                  zIndex: 100,
                }}
              />
            )}
          </AnimatePresence>
        </div>
        {isEditMode && <TranslationEditor />}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href=""
          className="fixed transition-colors duration-700 h-[36px] w-[36px] sm:h-[46px] sm:w-[46px] text-center aspect-square cursor-pointer left-3 bottom-3 z-50 bg-[#ccc] text-gerussiRed hover:text-green-600 text-2xl rounded-full"
        >
          <FontAwesomeIcon
            className="mt-[2px] w-[34px] h-[34px] sm:w-11 sm:h-11"
            icon={faWhatsapp}
          />
        </a>
      </AppContext.Provider>
    </NavigationProvider>
  );
}

export default App;
