// src/routesConfig.js

import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import Green from "./pages/Green";
import Lavorazioni from "./pages/Lavorazioni";
import Servizi from "./pages/Servizi";
import Prova from "./pages/materials/Prova";
import Contact from "./pages/Contact";
import Lavora from "./pages/Lavora";
import Sectors from "./pages/Sectors";
import Pillows from "./pages/sectors/Pillows";
import Sofas from "./pages/sectors/Sofas";
import Toys from "./pages/sectors/Toys";
import PetBeds from "./pages/sectors/PetBeds";
import PrepareOpen from "./pages/categories/PrepareOpen";
import Recycling from "./pages/categories/Recycling";
import FillLine from "./pages/categories/FillLine";
import WeighDose from "./pages/categories/WeighDose";
import MixStoreTransform from "./pages/categories/MixStoreTransform";
import AutomationTransportFiltering from "./pages/categories/AutomationTransportFiltering";
import CP1N from "./pages/products/CP1N";
import AB450NL from "./pages/products/AB450NL";
import CD400HMI from "./pages/products/CD400HMI";
import SF3CAL from "./pages/products/SF3CAL";
import T300 from "./pages/products/T300";
import TG500 from "./pages/products/TG500";
import R3PV from "./pages/products/R3PV";
import IC1 from "./pages/products/IC1";
import SR5HMI from "./pages/products/SR5HMI";
import TP1 from "./pages/products/TP1";
import M1P from "./pages/products/M1P";
import MSF200 from "./pages/products/MSF200";
import MatPillow from "./pages/materials/MatPillow";
import MatSofa from "./pages/materials/MatSofa";
import MatToy from "./pages/materials/MatToy";
import matBlanket from "./pages/materials/matBlanket";
import Materiali from "./pages/Materiali";
import NotFound from "./components/NotFound";

// Define supported languages
export const languages = ["en", "it", "fr", "es"];

// Define routes configuration as an array
export const routesConfig = [
  {
    lang: "en",
    basePath: "/en",
    routes: [
      // Home route exists but is hidden in the navbar.
      {
        key: "home",
        path: "/",
        component: Home,
        showInNav: false,
        nameKey: "nav.home",
      },
      {
        key: "who",
        path: "/company",
        component: AboutUs,
        showInNav: true,
        nameKey: "nav.who",
      },
      {
        key: "lavorazioni",
        path: "/processings",
        component: Lavorazioni,
        showInNav: true,
        nameKey: "nav.prodotti", // renamed to "Prodotti"
        categories: [
          {
            key: "PrepareOpen",
            path: "/prepare-and-open",
            component: PrepareOpen,
            nameKey: "nav.PrepareOpen",
            products: ["CP1N", "AB450NL", "CD400HMI"],
          },
          {
            key: "Recycling",
            path: "/machines-for-recycling",
            component: Recycling,
            nameKey: "nav.Recycling",
            products: ["SF3CAL", "T300", "TG500"],
          },
          {
            key: "FillLine",
            path: "/fill-and-cover",
            component: FillLine,
            nameKey: "nav.FillLine",
            products: ["R3PV", "IC1", "SR5HMI"],
          },
          {
            key: "WeighDose",
            path: "/weigh-and-dose",
            component: WeighDose,
            nameKey: "nav.WeighDose",
            products: ["CP1N", "SR5HMI", "TP1"],
          },
          {
            key: "MixStoreTransform",
            path: "/mix-store-transform",
            component: MixStoreTransform,
            nameKey: "nav.MixStoreTransform",
            products: ["M1P", "MSF200"],
          },
          {
            key: "AutomationTransportFiltering",
            path: "/automation-transport-filtering",
            component: AutomationTransportFiltering,
            nameKey: "nav.AutomationTransportFiltering",
            products: [],
          },
        ],
      },
      {
        key: "sectors",
        path: "/sectors",
        component: Sectors,
        showInNav: true,
        nameKey: "nav.sectors",
        pages: [
          {
            key: "Pillows",
            path: "/pillows-and-cushions",
            component: Pillows,
            nameKey: "nav.Pillows",
          },
          {
            key: "Sofas",
            path: "/sofas",
            component: Sofas,
            nameKey: "nav.Sofas",
          },
          { key: "Toys", path: "/toys", component: Toys, nameKey: "nav.Toys" },
          {
            key: "PetBeds",
            path: "/pet-beds",
            component: PetBeds,
            nameKey: "nav.PetBeds",
          },
        ],
      },
      {
        key: "green",
        path: "/green",
        component: Green,
        showInNav: true,
        nameKey: "nav.green",
      },
      {
        key: "servizi",
        path: "/gerussi-services",
        component: Servizi,
        showInNav: true,
        nameKey: "nav.servizi",
      },
      {
        key: "contact",
        path: "/contacts",
        component: Contact,
        showInNav: true,
        nameKey: "nav.contact",
        pages: [
          {
            key: "prova",
            path: "/material-test",
            component: Prova,
            nameKey: "nav.prova",
          },
          {
            key: "lavora",
            path: "/work-with-us",
            component: Lavora,
            nameKey: "nav.lavora",
          },
        ],
      },
    ],
    products: {
      CP1N: {
        key: "CP1N",
        path: "/dispenser-box-cp1n-gerussi",
        component: CP1N,
        displayNameKey: "CP1N",
      },
      AB450NL: {
        key: "AB450NL",
        path: "/ab450n-gerussi",
        component: AB450NL,
        displayNameKey: "AB450NL",
      },
      CD400HMI: {
        key: "CD400HMI",
        path: "/CD400HMI",
        component: CD400HMI,
        displayNameKey: "CD400HMI",
      },
      SF3CAL: {
        key: "SF3CAL",
        path: "/SF3CAL",
        component: SF3CAL,
        displayNameKey: "SF3CAL",
      },
      T300: {
        key: "T300",
        path: "/T300",
        component: T300,
        displayNameKey: "T300",
      },
      TG500: {
        key: "TG500",
        path: "/TG500",
        component: TG500,
        displayNameKey: "TG500",
      },
      R3PV: {
        key: "R3PV",
        path: "/R3PV",
        component: R3PV,
        displayNameKey: "R3PV",
      },
      IC1: { key: "IC1", path: "/IC1", component: IC1, displayNameKey: "IC1" },
      SR5HMI: {
        key: "SR5HMI",
        path: "/SR5HMI",
        component: SR5HMI,
        displayNameKey: "SR5HMI",
      },
      TP1: { key: "TP1", path: "/TP1", component: TP1, displayNameKey: "TP1" },
      M1P: { key: "M1P", path: "/M1P", component: M1P, displayNameKey: "M1P" },
      MSF200: {
        key: "MSF200",
        path: "/MSF200",
        component: MSF200,
        displayNameKey: "MSF200",
      },
    },
  },
  {
    lang: "es",
    basePath: "/es",
    routes: [
      {
        key: "home",
        path: "/",
        component: Home,
        showInNav: false,
        nameKey: "nav.home",
      },
      {
        key: "who",
        path: "/empresa",
        component: AboutUs,
        showInNav: true,
        nameKey: "nav.who",
      },
      {
        key: "lavorazioni",
        path: "/procesos",
        component: Lavorazioni,
        showInNav: true,
        nameKey: "nav.prodotti", // renamed to "Prodotti"
        categories: [
          {
            key: "PrepareOpen",
            path: "/preparar-y-abrir",
            component: PrepareOpen,
            nameKey: "nav.PrepareOpen",
            products: ["CP1N", "AB450NL", "CD400HMI"],
          },
          {
            key: "Recycling",
            path: "/maquinas-para-reciclaje",
            component: Recycling,
            nameKey: "nav.Recycling",
            products: ["SF3CAL", "T300", "TG500"],
          },
          {
            key: "FillLine",
            path: "/llenar-y-cubrir",
            component: FillLine,
            nameKey: "nav.FillLine",
            products: ["R3PV", "IC1", "SR5HMI"],
          },
          {
            key: "WeighDose",
            path: "/pesar-y-dosificar",
            component: WeighDose,
            nameKey: "nav.WeighDose",
            products: ["CP1N", "SR5HMI", "TP1"],
          },
          {
            key: "MixStoreTransform",
            path: "/mezclar-almacenar-y-transformar",
            component: MixStoreTransform,
            nameKey: "nav.MixStoreTransform",
            products: ["M1P", "MSF200"],
          },
          {
            key: "AutomationTransportFiltering",
            path: "/automatizacion-transporte-y-filtrado",
            component: AutomationTransportFiltering,
            nameKey: "nav.AutomationTransportFiltering",
            products: [],
          },
        ],
      },
      {
        key: "sectors",
        path: "/sectores",
        component: Sectors,
        showInNav: true,
        nameKey: "nav.sectors",
        pages: [
          {
            key: "Pillows",
            path: "/almohadas-y-cojines",
            component: Pillows,
            nameKey: "nav.Pillows",
          },
          {
            key: "Sofas",
            path: "/sofás",
            component: Sofas,
            nameKey: "nav.Sofas",
          },
          {
            key: "Toys",
            path: "/juguetes",
            component: Toys,
            nameKey: "nav.Toys",
          },
          {
            key: "PetBeds",
            path: "/camas-para-mascotas",
            component: PetBeds,
            nameKey: "nav.PetBeds",
          },
        ],
      },
      {
        key: "green",
        path: "/verde",
        component: Green,
        showInNav: true,
        nameKey: "nav.green",
      },
      {
        key: "servizi",
        path: "/servicios-gerussi",
        component: Servizi,
        showInNav: true,
        nameKey: "nav.servizi",
      },
      {
        key: "contact",
        path: "/contacto",
        component: Contact,
        showInNav: true,
        nameKey: "nav.contact",
        pages: [
          {
            key: "prova",
            path: "/prueba-materiales",
            component: Prova,
            nameKey: "nav.prova",
          },
          {
            key: "lavora",
            path: "/trabaja-con-nosotros",
            component: Lavora,
            nameKey: "nav.lavora",
          },
        ],
      },
    ],
    products: {
      CP1N: {
        key: "CP1N",
        path: "/caja-distribuidor-cp1n-gerussi",
        component: CP1N,
        displayNameKey: "CP1N",
      },
      AB450NL: {
        key: "AB450NL",
        path: "/ab450n-gerussi",
        component: AB450NL,
        displayNameKey: "AB450NL",
      },
      CD400HMI: {
        key: "CD400HMI",
        path: "/CD400HMI",
        component: CD400HMI,
        displayNameKey: "CD400HMI",
      },
      SF3CAL: {
        key: "SF3CAL",
        path: "/SF3CAL",
        component: SF3CAL,
        displayNameKey: "SF3CAL",
      },
      T300: {
        key: "T300",
        path: "/T300",
        component: T300,
        displayNameKey: "T300",
      },
      TG500: {
        key: "TG500",
        path: "/TG500",
        component: TG500,
        displayNameKey: "TG500",
      },
      R3PV: {
        key: "R3PV",
        path: "/R3PV",
        component: R3PV,
        displayNameKey: "R3PV",
      },
      IC1: { key: "IC1", path: "/IC1", component: IC1, displayNameKey: "IC1" },
      SR5HMI: {
        key: "SR5HMI",
        path: "/SR5HMI",
        component: SR5HMI,
        displayNameKey: "SR5HMI",
      },
      TP1: { key: "TP1", path: "/TP1", component: TP1, displayNameKey: "TP1" },
      M1P: { key: "M1P", path: "/M1P", component: M1P, displayNameKey: "M1P" },
      MSF200: {
        key: "MSF200",
        path: "/MSF200",
        component: MSF200,
        displayNameKey: "MSF200",
      },
    },
  },
  {
    lang: "fr",
    basePath: "/fr",
    routes: [
      {
        key: "home",
        path: "/",
        component: Home,
        showInNav: false,
        nameKey: "nav.home",
      },
      {
        key: "who",
        path: "/entreprise",
        component: AboutUs,
        showInNav: true,
        nameKey: "nav.who",
      },
      {
        key: "lavorazioni",
        path: "/processus",
        component: Lavorazioni,
        showInNav: true,
        nameKey: "nav.prodotti", // renamed to "Prodotti"
        categories: [
          {
            key: "PrepareOpen",
            path: "/preparer-et-ouvrir",
            component: PrepareOpen,
            nameKey: "nav.PrepareOpen",
            products: ["CP1N", "AB450NL", "CD400HMI"],
          },
          {
            key: "Recycling",
            path: "/machines-pour-recyclage",
            component: Recycling,
            nameKey: "nav.Recycling",
            products: ["SF3CAL", "T300", "TG500"],
          },
          {
            key: "FillLine",
            path: "/remplir-et-couvrir",
            component: FillLine,
            nameKey: "nav.FillLine",
            products: ["R3PV", "IC1", "SR5HMI"],
          },
          {
            key: "WeighDose",
            path: "/peser-et-doser",
            component: WeighDose,
            nameKey: "nav.WeighDose",
            products: ["CP1N", "SR5HMI", "TP1"],
          },
          {
            key: "MixStoreTransform",
            path: "/melanger-stockage-et-transformer",
            component: MixStoreTransform,
            nameKey: "nav.MixStoreTransform",
            products: ["M1P", "MSF200"],
          },
          {
            key: "AutomationTransportFiltering",
            path: "/automatisation-transport-et-filtrage",
            component: AutomationTransportFiltering,
            nameKey: "nav.AutomationTransportFiltering",
            products: [],
          },
        ],
      },
      {
        key: "sectors",
        path: "/secteurs",
        component: Sectors,
        showInNav: true,
        nameKey: "nav.sectors",
        pages: [
          {
            key: "Pillows",
            path: "/coussins-et-oreillers",
            component: Pillows,
            nameKey: "nav.Pillows",
          },
          {
            key: "Sofas",
            path: "/divans",
            component: Sofas,
            nameKey: "nav.Sofas",
          },
          {
            key: "Toys",
            path: "/peluches",
            component: Toys,
            nameKey: "nav.Toys",
          },
          {
            key: "PetBeds",
            path: "/caches-pour-animaux",
            component: PetBeds,
            nameKey: "nav.PetBeds",
          },
        ],
      },
      {
        key: "green",
        path: "/vert",
        component: Green,
        showInNav: true,
        nameKey: "nav.green",
      },
      {
        key: "servizi",
        path: "/services-gerussi",
        component: Servizi,
        showInNav: true,
        nameKey: "nav.servizi",
      },
      {
        key: "contact",
        path: "/contact",
        component: Contact,
        showInNav: true,
        nameKey: "nav.contact",
        pages: [
          {
            key: "prova",
            path: "/test-materiaux",
            component: Prova,
            nameKey: "nav.prova",
          },
          {
            key: "lavora",
            path: "/travailler-avec-nous",
            component: Lavora,
            nameKey: "nav.lavora",
          },
        ],
      },
    ],
    products: {
      CP1N: {
        key: "CP1N",
        path: "/boite-distributeur-cp1n-gerussi",
        component: CP1N,
        displayNameKey: "CP1N",
      },
      AB450NL: {
        key: "AB450NL",
        path: "/ouvre-balles-ab450n-gerussi",
        component: AB450NL,
        displayNameKey: "AB450NL",
      },
      CD400HMI: {
        key: "CD400HMI",
        path: "/CD400HMI",
        component: CD400HMI,
        displayNameKey: "CD400HMI",
      },
      SF3CAL: {
        key: "SF3CAL",
        path: "/SF3CAL",
        component: SF3CAL,
        displayNameKey: "SF3CAL",
      },
      T300: {
        key: "T300",
        path: "/T300",
        component: T300,
        displayNameKey: "T300",
      },
      TG500: {
        key: "TG500",
        path: "/TG500",
        component: TG500,
        displayNameKey: "TG500",
      },
      R3PV: {
        key: "R3PV",
        path: "/R3PV",
        component: R3PV,
        displayNameKey: "R3PV",
      },
      IC1: { key: "IC1", path: "/IC1", component: IC1, displayNameKey: "IC1" },
      SR5HMI: {
        key: "SR5HMI",
        path: "/SR5HMI",
        component: SR5HMI,
        displayNameKey: "SR5HMI",
      },
      TP1: { key: "TP1", path: "/TP1", component: TP1, displayNameKey: "TP1" },
      M1P: { key: "M1P", path: "/M1P", component: M1P, displayNameKey: "M1P" },
      MSF200: {
        key: "MSF200",
        path: "/MSF200",
        component: MSF200,
        displayNameKey: "MSF200",
      },
    },
  },
  {
    lang: "it",
    basePath: "/",
    routes: [
      {
        key: "home",
        path: "/",
        component: Home,
        showInNav: false,
        nameKey: "nav.home",
      },
      {
        key: "who",
        path: "/azienda-gerussi",
        component: AboutUs,
        showInNav: true,
        nameKey: "nav.who",
      },
      {
        key: "lavorazioni",
        path: "/lavorazioni",
        component: Lavorazioni,
        showInNav: true,
        nameKey: "nav.prodotti", // renamed to "Prodotti"
        categories: [
          {
            key: "PrepareOpen",
            path: "/macchinari-preparare-aprire",
            component: PrepareOpen,
            nameKey: "nav.PrepareOpen",
            products: ["CP1N", "AB450NL", "CD400HMI"],
          },
          {
            key: "Recycling",
            path: "/macchinari-riciclo-scarti",
            component: Recycling,
            nameKey: "nav.Recycling",
            products: ["SF3CAL", "T300", "TG500"],
          },
          {
            key: "FillLine",
            path: "/macchinari-riempire-foderare",
            component: FillLine,
            nameKey: "nav.FillLine",
            products: ["R3PV", "IC1", "SR5HMI"],
          },
          {
            key: "WeighDose",
            path: "/macchinari-pesare-dosare",
            component: WeighDose,
            nameKey: "nav.WeighDose",
            products: ["CP1N", "SR5HMI", "TP1"],
          },
          {
            key: "MixStoreTransform",
            path: "/mescolare-stoccare-trasformare",
            component: MixStoreTransform,
            nameKey: "nav.MixStoreTransform",
            products: ["M1P", "MSF200"],
          },
          {
            key: "AutomationTransportFiltering",
            path: "/automazione-trasporto-filtraggio",
            component: AutomationTransportFiltering,
            nameKey: "nav.AutomationTransportFiltering",
            products: [],
          },
        ],
      },
      {
        key: "sectors",
        path: "/settori",
        component: Sectors,
        showInNav: true,
        nameKey: "nav.sectors",
        pages: [
          {
            key: "Pillows",
            path: "/macchinari-imbottitura-cuscini-guanciali",
            component: Pillows,
            nameKey: "nav.Pillows",
          },
          {
            key: "Sofas",
            path: "/imbottitura-divani",
            component: Sofas,
            nameKey: "nav.Sofas",
          },
          {
            key: "Toys",
            path: "/imbottitura-peluches",
            component: Toys,
            nameKey: "nav.Toys",
          },
          {
            key: "PetBeds",
            path: "/imbottitura-cuscini-animali",
            component: PetBeds,
            nameKey: "nav.PetBeds",
          },
        ],
      },
      {
        key: "green",
        path: "/green-gerussi",
        component: Green,
        showInNav: true,
        nameKey: "nav.green",
      },
      {
        key: "servizi",
        path: "/servizi-gerussi",
        component: Servizi,
        showInNav: true,
        nameKey: "nav.servizi",
      },
      {
        key: "contact",
        path: "/contatti",
        component: Contact,
        showInNav: true,
        nameKey: "nav.contact",
        pages: [
          {
            key: "prova",
            path: "/prova-materiali",
            component: Prova,
            nameKey: "nav.prova",
          },
          {
            key: "lavora",
            path: "/lavora-con-noi",
            component: Lavora,
            nameKey: "nav.lavora",
          },
        ],
      },
      {
        key: "notFound",
        path: "*",
        component: NotFound,
        showInNav: false,
        nameKey: "notFound",
      },
    ],
    products: {
      CP1N: {
        key: "CP1N",
        path: "/caricatore-cp1n-gerussi",
        component: CP1N,
        displayNameKey: "CP1N",
      },
      AB450NL: {
        key: "AB450NL",
        path: "/apriballe-ab450n-gerussi",
        component: AB450NL,
        displayNameKey: "AB450NL",
      },
      CD400HMI: {
        key: "CD400HMI",
        path: "/aprifibra-cd400hmi-gerussi",
        component: CD400HMI,
        displayNameKey: "CD400HMI",
      },
      SF3CAL: {
        key: "SF3CAL",
        path: "/sfilettatrice-sf3cal-gerussi",
        component: SF3CAL,
        displayNameKey: "SF3CAL",
      },
      T300: {
        key: "T300",
        path: "/sminuzzatrice-t300-gerussi",
        component: T300,
        displayNameKey: "T300",
      },
      TG500: {
        key: "TG500",
        path: "/taglierina-tg500-tg500r",
        component: TG500,
        displayNameKey: "TG500",
      },
      R3PV: {
        key: "R3PV",
        path: "/riempitrice-r3pv-gerussi",
        component: R3PV,
        displayNameKey: "R3PV",
      },
      IC1: {
        key: "IC1",
        path: "/infilacuscini-ic1-gerussi",
        component: IC1,
        displayNameKey: "IC1",
      },
      SR5HMI: {
        key: "SR5HMI",
        path: "/sr5hmi-gerussi",
        component: SR5HMI,
        displayNameKey: "SR5HMI",
      },
      TP1: {
        key: "TP1",
        path: "/tavolo-pesatura-tp1-gerussi",
        component: TP1,
        displayNameKey: "TP1",
      },
      M1P: {
        key: "M1P",
        path: "/mescolatore-m1p-gerussi",
        component: M1P,
        displayNameKey: "M1P",
      },
      MSF200: {
        key: "MSF200",
        path: "/msf200-gerussi",
        component: MSF200,
        displayNameKey: "MSF200",
      },
    },
  },
];
