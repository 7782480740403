// i18n.js
import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import { loadTranslations } from "./components/translations";

const i18n = i18next.use(initReactI18next);

// Initialize without translations first
i18n.init({
  fallbackLng: false,
  debug: false,
  interpolation: { escapeValue: false },
  lng: "en",
  resources: {
    en: { global: {} },
    it: { global: {} },
    fr: { global: {} },
    es: { global: {} },
  },
});

// Load initial translations
loadTranslations(i18n.language)
  .then((initialTranslations) => {
    i18n.addResourceBundle(
      i18n.language,
      "global",
      initialTranslations,
      true,
      true
    );
  })
  .catch((error) => {
    console.error("Error loading initial translations:", error);
  });

// Add a custom method to handle translation loading
i18n.loadLanguageTranslations = async (language) => {
  try {
    const translations = await loadTranslations(language);
    i18n.addResourceBundle(language, "global", translations, true, true);
  } catch (error) {
    console.error(`Error loading translations for ${language}:`, error);
  }
};

export default i18n;
