import React from "react";
import getPathByKey from "../utils/getPathByKey ";

const FlipCard = ({ Image, text, to, ptext }) => {
  const path = getPathByKey(to);
  return (
    <a
      href={path}
      className="block group relative w-full h-0 pb-[56.25%] border border-gray-300 rounded-lg overflow-hidden"
    >
      {/* Main image */}
      <div className="absolute inset-0">
        <img
          src={Image}
          alt="Card Image"
          className="object-cover w-full h-full"
        />
      </div>

      {/* Sliding content wrapper - includes both background and text */}
      <div
        className={`absolute inset-x-0 bottom-0 transform transition-height duration-500 ease-[cubic-bezier(0.34,1.56,0.64,1)] group-hover:translate-y-0 ${
          ptext ? "translate-y-2/3" : "translate-y-[calc(100%-4rem)] h-full"
        }`}
      >
        {/* Red background */}
        <div
          className={`absolute inset-0 bg-red-800/50 ${
            ptext ? "h-full" : "h-16"
          } ${
            !ptext &&
            "group-hover:h-full transition-height duration-500 ease-[cubic-bezier(0.34,1.56,0.64,1)]"
          }`}
        />

        {/* Content container */}
        <div className={`relative p-4 text-white ${!ptext && "h-16"}`}>
          <h3 className="text-lg lg:text-2xl font-light mb-2">{text}</h3>
          {ptext && (
            <p className="text-lg opacity-0 transform translate-y-full transition-height duration-500 delay-200 ease-[cubic-bezier(0.34,1.56,0.64,1)] group-hover:opacity-100 group-hover:translate-y-0">
              {ptext}
            </p>
          )}
        </div>
      </div>
    </a>
  );
};

export default FlipCard;
