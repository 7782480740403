// src/contexts/NavigationContext.js
import React, { createContext, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { loadTranslations } from "./components/translations";

const NavigationContext = createContext();

export const NavigationProvider = ({ children }) => {
  const { i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
  const [isTranslationsLoaded, setIsTranslationsLoaded] = useState(false);

  useEffect(() => {
    const loadLanguage = async () => {
      try {
        const translations = await loadTranslations(i18n.language);
        i18n.addResourceBundle(
          i18n.language,
          "global",
          translations,
          true,
          true
        );
        setCurrentLanguage(i18n.language);
        setIsTranslationsLoaded(true);
      } catch (error) {
        console.error("Error loading translations:", error);
        setIsTranslationsLoaded(true); // Set to true even on error to prevent infinite loading
      }
    };

    setIsTranslationsLoaded(false);
    loadLanguage();
  }, [i18n.language]);

  if (!isTranslationsLoaded) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gerussiRed"></div>
      </div>
    );
  }

  return (
    <NavigationContext.Provider value={{ currentLanguage }}>
      {children}
    </NavigationContext.Provider>
  );
};

export const useNavigation = () => useContext(NavigationContext);
