// src/components/language-selector.js
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaGlobe, FaRegEdit } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { parseLocationToKeys, buildPathForLanguage } from "../utils/routeUtils";
import { loadTranslations } from "./translations";

const languages = [
  { code: "en", label: "English" },
  { code: "it", label: "Italiano" },
  { code: "fr", label: "Français" },
  { code: "es", label: "Español" },
];

const EDIT_PASSWORD = "justtomakesure";
const STORAGE_KEY = "translatorAuth";
const EXPIRATION_DAYS = 30;

const checkAuthStatus = () => {
  const stored = localStorage.getItem(STORAGE_KEY);
  if (!stored) return false;

  try {
    const { expiry } = JSON.parse(stored);
    if (new Date().getTime() > expiry) {
      localStorage.removeItem(STORAGE_KEY);
      return false;
    }
    return true;
  } catch (error) {
    localStorage.removeItem(STORAGE_KEY);
    return false;
  }
};

const setAuthStatus = () => {
  const expiryDate = new Date();
  expiryDate.setDate(expiryDate.getDate() + EXPIRATION_DAYS);
  localStorage.setItem(
    STORAGE_KEY,
    JSON.stringify({ authenticated: true, expiry: expiryDate.getTime() })
  );
};

const toggleEditMode = () => {
  if (window.location.hash === "#translate") {
    window.history.replaceState(
      null,
      "",
      window.location.pathname + window.location.search
    );
  } else {
    if (checkAuthStatus()) {
      window.location.hash = "#translate";
    } else {
      const password = prompt("Enter password to enable translation editor:");
      if (password === EDIT_PASSWORD) {
        setAuthStatus();
        window.location.hash = "#translate";
      } else {
        alert("Incorrect password. Access denied.");
      }
    }
  }
};

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [hovered, setHovered] = useState(false);
  const [isChangingLanguage, setIsChangingLanguage] = useState(false);

  const handleMouseEnter = () => setHovered(true);
  const handleMouseLeave = () => setHovered(false);

  const currentLabel =
    languages.find((l) => l.code === i18n.language)?.label ||
    i18n.language.toUpperCase();

  const changeLanguage = async (newLang) => {
    if (newLang === i18n.language || isChangingLanguage) {
      setHovered(false);
      return;
    }

    setIsChangingLanguage(true);

    try {
      // Load translations before changing the language
      const translations = await loadTranslations(newLang);
      i18n.addResourceBundle(newLang, "global", translations, true, true);

      // Parse current location
      const keys = parseLocationToKeys(location.pathname);

      // Build new path
      const newPath = buildPathForLanguage(
        newLang,
        keys.routeKey,
        keys.categoryKey,
        keys.productKey,
        keys.pageKey
      );

      // Change the language first
      await i18n.changeLanguage(newLang);

      // Navigate with languageChange state
      navigate(newPath, {
        replace: true,
        state: { languageChange: true },
      });
    } catch (error) {
      console.error("Error changing language:", error);
    } finally {
      setIsChangingLanguage(false);
      setHovered(false);
    }
  };

  return (
    <div className="relative z-40 flex items-center gap-2">
      {/* Editor Toggle Button */}
      <button
        onClick={toggleEditMode}
        className="px-2 py-1 bg-gray-200 rounded focus:outline-none hover:bg-gray-300 transition-colors duration-200"
        title="Toggle Translation Editor"
      >
        {window.location.hash === "#translate" ? (
          <span className="text-red-500">×</span>
        ) : (
          <FaRegEdit className="text-gray-600" />
        )}
      </button>

      {/* Language Selector Dropdown */}
      <div className="relative">
        <button
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          className="focus:outline-none flex items-center gap-1 px-2 py-1 rounded hover:bg-gray-100 transition-colors duration-200"
          title="Change Language"
          disabled={isChangingLanguage}
        >
          <FaGlobe className="text-gray-600" />
          <span>{currentLabel}</span>
        </button>

        {/* Dropdown Menu */}
        <div
          className={`absolute top-full right-0 bg-white border rounded shadow-lg z-40 transition-all duration-300 ${
            hovered && !isChangingLanguage
              ? "opacity-100 translate-y-0 pointer-events-auto"
              : "opacity-0 -translate-y-2 pointer-events-none"
          }`}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {languages
            .filter((lng) => lng.code !== i18n.language)
            .map((lng) => (
              <button
                key={lng.code}
                onClick={() => changeLanguage(lng.code)}
                className="w-full text-left px-4 py-2 hover:bg-gray-100 transition-colors duration-200 whitespace-nowrap"
                disabled={isChangingLanguage}
              >
                {lng.label}
              </button>
            ))}
        </div>
      </div>
    </div>
  );
};

export default LanguageSelector;
