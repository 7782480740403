import React, { useContext, useEffect, useState, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Replace with wherever your AppContext or NavigationContext is
import { AppContext } from "../App";

// Your existing functions
import { loadTranslations, saveTranslations } from "./translations";
import {
  flattenTranslations,
  unflattenTranslations,
} from "../utils/translationUtils";

// We'll import your entire routesConfig
import { routesConfig } from "../routesConfig";

const ALL_LANGS = ["it", "en", "fr", "es"];

/**
 * 1) Determine which "langConfig" from routesConfig to use, based on i18n.language.
 * 2) Remove that langConfig's basePath from the start of location.pathname (if any).
 * 3) Attempt to match the leftover path with:
 *    - A product in langConfig.products
 *    - A route in langConfig.routes (including subpages, categories, etc.)
 * 4) Return an object like:
 *    { productKey: "cp1n" } or { routeKey: "sectors", pageKey: "Sofas" } etc.
 */
function getContextFromRoutesConfig(pathname, currentLang) {
  // 1) Find the matching language block
  // If not found, fallback to the "it" block or the first block
  let langConfig =
    routesConfig.find((cfg) => cfg.lang === currentLang) ||
    routesConfig.find((cfg) => cfg.lang === "it") ||
    routesConfig[0];

  if (!langConfig) {
    console.warn("[getContextFromRoutesConfig] No langConfig found at all!");
    return null;
  }

  // 2) Remove basePath (e.g. "/en") from start of pathname
  // e.g. if basePath="/en" and pathname="/en/caricatore-cp1n-gerussi", leftover becomes "/caricatore-cp1n-gerussi"
  let leftover = pathname;
  if (langConfig.basePath !== "/" && leftover.startsWith(langConfig.basePath)) {
    leftover = leftover.slice(langConfig.basePath.length) || "/";
  }

  // Clean up double slashes, etc.
  if (!leftover.startsWith("/")) leftover = "/" + leftover;

  // If leftover is just "/", we might be on the homepage
  if (leftover === "/") {
    // Use the route that has path="/" (the home route).
    // Or just say routeKey= "home".
    const maybeHome = langConfig.routes.find((r) => r.key === "home");
    if (maybeHome) {
      return { routeKey: "home" };
    } else {
      // fallback
      return { routeKey: "home" };
    }
  }

  // 3) Try to match leftover path with a product path
  // Example: leftover="/caricatore-cp1n-gerussi", and langConfig.products.CP1N.path="/caricatore-cp1n-gerussi"
  const productEntries = Object.entries(langConfig.products || {});
  for (let [prodKey, prodObj] of productEntries) {
    if (prodObj.path === leftover) {
      // The product's i18n JSON is likely "cp1n.*" if prodKey="CP1N" => we do prodKey.toLowerCase().
      // If your JSON is under "cp1n" keys, do this:
      return { productKey: prodKey.toLowerCase() };
    }
  }

  // 4) If not a product, try to match leftover path in the main routes
  // We'll do a function that traverses the route array, categories, etc.
  function findRouteInList(routeList, pathToMatch) {
    for (let rt of routeList) {
      if (rt.path === pathToMatch) {
        // found an exact match
        return { routeKey: rt.key };
      }
      // If it has categories
      if (rt.categories) {
        for (let cat of rt.categories) {
          if (rt.path + cat.path === pathToMatch) {
            // e.g. leftover="/lavorazioni/macchinari-preparare-aprire"
            // routeKey= rt.key ( "lavorazioni" ), categoryKey= cat.key
            return { routeKey: rt.key, categoryKey: cat.key };
          }
          // If that category has products array, we've already handled those above, so skip
        }
      }
      // If it has sub-pages
      if (rt.pages) {
        for (let pg of rt.pages) {
          if (rt.path + pg.path === pathToMatch) {
            // e.g. leftover="/contact/work-with-us"
            return { routeKey: rt.key, pageKey: pg.key };
          }
        }
      }
    }
    return null;
  }

  // We'll do a BFS or simple approach: just top-level routes for this lang
  const match = findRouteInList(langConfig.routes, leftover);
  if (match) {
    return match;
  }

  // If no match at all
  console.warn(
    "[getContextFromRoutesConfig] No route/product match for leftover:",
    leftover
  );
  return null;
}

/**
 * buildKeyFilter: Given the route context (like {productKey: "cp1n"}),
 * only return flattened keys that contain "cp1n".
 * If the context is { routeKey: "home" }, we only match keys that contain "home".
 *
 * If you want certain global keys (like "nav.*") to always appear,
 * you can do: if (key starts with "nav.") => always include, etc.
 */
function buildKeyFilter(context, flatObj) {
  const allKeys = Object.keys(flatObj || {});
  if (!context) {
    return [];
  }

  // If context has productKey
  if (context.productKey) {
    // e.g. "cp1n"
    const pkLower = context.productKey.toLowerCase();
    return allKeys.filter((k) => k.toLowerCase().includes(pkLower));
  }

  // If context has routeKey
  if (context.routeKey) {
    const rkLower = context.routeKey.toLowerCase();
    return allKeys.filter((k) => {
      // Example: user on "home"? Then only match keys containing "home."
      // But you might want to also show "nav.*" so user can translate the menu.
      // If you want that, add a condition: if k.startsWith("nav.") => true
      if (k.startsWith("nav.")) {
        return true; // always include nav
      }
      return k.toLowerCase().includes(rkLower);
    });
  }

  // If context has categoryKey or pageKey, do likewise
  if (context.categoryKey) {
    const ckLower = context.categoryKey.toLowerCase();
    // plus routeKey if we want both
    const rkLower = context.routeKey ? context.routeKey.toLowerCase() : "";
    return allKeys.filter((k) => {
      if (k.startsWith("nav.")) return true; // always show nav
      // Must match the routeKey AND the categoryKey?
      return (
        (!rkLower || k.toLowerCase().includes(rkLower)) &&
        k.toLowerCase().includes(ckLower)
      );
    });
  }

  if (context.pageKey) {
    const pgLower = context.pageKey.toLowerCase();
    const rkLower = context.routeKey ? context.routeKey.toLowerCase() : "";
    return allKeys.filter((k) => {
      if (k.startsWith("nav.")) return true;
      return (
        (!rkLower || k.toLowerCase().includes(rkLower)) &&
        k.toLowerCase().includes(pgLower)
      );
    });
  }

  // fallback => no match
  return [];
}

const TranslationEditor = () => {
  const location = useLocation();
  const { i18n } = useTranslation();
  const { isEditMode, setIsEditMode } = useContext(AppContext);

  const [flatData, setFlatData] = useState({});
  const [initialData, setInitialData] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [message, setMessage] = useState(null);
  const [confirmMissing, setConfirmMissing] = useState(null);
  const [currentContext, setCurrentContext] = useState(null);

  // Toggle columns for each language
  const [langOpen, setLangOpen] = useState({
    it: true,
    en: true,
    fr: true,
    es: true,
  });

  // 1) figure out the route context from routesConfig
  useEffect(() => {
    if (isEditMode) {
      const ctx = getContextFromRoutesConfig(location.pathname, i18n.language);
      setCurrentContext(ctx);
      console.log("[TranslationEditor] route context =>", ctx);
    }
  }, [isEditMode, location.pathname, i18n.language]);

  // 2) load all translations in flattened form
  useEffect(() => {
    if (!isEditMode) return;

    const loadAll = async () => {
      const tmpFlat = {};
      const tmpInit = {};

      for (const lng of ALL_LANGS) {
        try {
          const data = await loadTranslations(lng);
          const flat = flattenTranslations(data || {});
          tmpFlat[lng] = flat;
          tmpInit[lng] = { ...flat };
          // Debug
          console.log(`[TranslationEditor] loaded ${lng}`, flat);
        } catch (err) {
          console.error("Error loading language:", lng, err);
          tmpFlat[lng] = {};
          tmpInit[lng] = {};
        }
      }
      setFlatData(tmpFlat);
      setInitialData(tmpInit);
    };

    loadAll();
  }, [isEditMode]);

  // close editor
  const closeEditor = useCallback(() => {
    window.history.replaceState(null, "", location.pathname + location.search);
    setIsEditMode(false);
  }, [location.pathname, location.search, setIsEditMode]);

  // handle field change
  const handleChange = useCallback((lng, key, newValue) => {
    setFlatData((prev) => ({
      ...prev,
      [lng]: { ...prev[lng], [key]: newValue },
    }));
  }, []);

  const toggleLanguage = useCallback((lng) => {
    setLangOpen((prev) => ({
      ...prev,
      [lng]: !prev[lng],
    }));
  }, []);

  // saving logic
  const doSaveAll = async () => {
    setIsSaving(true);
    setMessage(null);
    setConfirmMissing(null);

    try {
      let savedAnything = false;
      for (const lng of ALL_LANGS) {
        const curr = flatData[lng] || {};
        const init = initialData[lng] || {};
        let changed = false;
        for (const k of Object.keys(curr)) {
          if (curr[k] !== init[k]) {
            changed = true;
            break;
          }
        }
        if (changed) {
          const unflat = unflattenTranslations(curr);
          await saveTranslations(lng, unflat);
          setInitialData((p) => ({
            ...p,
            [lng]: { ...curr },
          }));
          savedAnything = true;
        }
      }

      if (savedAnything) {
        setMessage({
          type: "success",
          text: "Traduzioni salvate con successo!",
        });
        closeEditor();
      } else {
        setMessage({ type: "info", text: "Nessuna modifica da salvare." });
      }
    } catch (error) {
      setMessage({
        type: "error",
        text: "Errore nel salvataggio: " + (error.message || error),
      });
    }
    setIsSaving(false);
  };

  const handleSaveClick = useCallback(() => {
    // figure out changed keys
    const changedByLang = {};
    ALL_LANGS.forEach((lng) => {
      changedByLang[lng] = [];
      const curr = flatData[lng] || {};
      const init = initialData[lng] || {};
      for (const key of Object.keys(curr)) {
        if (curr[key] !== init[key]) {
          changedByLang[lng].push(key);
        }
      }
    });

    const allChangedKeys = new Set(
      ALL_LANGS.flatMap((lng) => changedByLang[lng])
    );

    const missing = [];
    allChangedKeys.forEach((k) => {
      const changedIn = ALL_LANGS.filter((l) => changedByLang[l].includes(k));
      const unaffected = ALL_LANGS.filter((l) => !changedIn.includes(k));
      const missingIn = [];
      unaffected.forEach((u) => {
        const val = flatData[u]?.[k] || "";
        if (!val) {
          missingIn.push(u);
        }
      });
      if (missingIn.length > 0) {
        missing.push({ key: k, changedIn, missingIn });
      }
    });

    if (missing.length > 0) {
      setConfirmMissing(missing);
    } else {
      doSaveAll();
    }
  }, [flatData, initialData]);

  if (!isEditMode) return null;

  // 3) get relevant keys for each language, union them
  let relevantKeys = [];
  if (currentContext) {
    for (const lng of ALL_LANGS) {
      const theseKeys = buildKeyFilter(currentContext, flatData[lng] || {});
      relevantKeys.push(...theseKeys);
    }
  }

  relevantKeys = Array.from(new Set(relevantKeys)).sort();
  console.log("[TranslationEditor] final relevantKeys =>", relevantKeys);

  return (
    <>
      {/* If partial translation missing => confirm */}
      {confirmMissing && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg w-full max-w-xl">
            <h3 className="text-xl font-bold mb-4">
              Attenzione: Traduzioni Parziali
            </h3>
            <p className="mb-2">
              Hai modificato alcune chiavi in una lingua ma non in altre. Vuoi
              salvare comunque?
            </p>
            <ul className="mb-4 max-h-32 overflow-y-auto list-disc list-inside">
              {confirmMissing.map((obj, i) => (
                <li key={i}>
                  <strong>{obj.key}</strong>:<br />
                  <span className="ml-4 text-sm">
                    Modificato in: {obj.changedIn.join(", ")}. <br />
                    Vuoto in: {obj.missingIn.join(", ")}.
                  </span>
                </li>
              ))}
            </ul>
            <div className="flex justify-end space-x-2">
              <button
                onClick={() => setConfirmMissing(null)}
                className="bg-gray-400 text-white px-3 py-1 rounded"
              >
                Annulla
              </button>
              <button
                onClick={doSaveAll}
                className="bg-blue-600 text-white px-3 py-1 rounded"
              >
                Salva Comunque
              </button>
            </div>
          </div>
        </div>
      )}

      {/* MAIN EDITOR OVERLAY */}
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-40">
        <div className="bg-white rounded-lg w-11/12 max-w-6xl h-5/6 flex flex-col relative">
          {/* Header */}
          <div className="flex justify-between items-center p-4 border-b">
            <div className="flex items-center space-x-2">
              <h2 className="text-2xl font-bold">Translation Editor</h2>
              {message && (
                <div
                  className={`ml-4 px-3 py-1 rounded ${
                    message.type === "success"
                      ? "bg-green-200 text-green-800"
                      : message.type === "error"
                      ? "bg-red-200 text-red-800"
                      : "bg-gray-200 text-gray-800"
                  }`}
                >
                  {message.text}
                </div>
              )}
            </div>
            <div className="flex items-center space-x-3">
              {ALL_LANGS.map((lng) => (
                <button
                  key={lng}
                  onClick={() => toggleLanguage(lng)}
                  className={`px-2 py-1 border rounded ${
                    langOpen[lng] ? "bg-blue-200" : "bg-gray-200"
                  }`}
                >
                  {lng.toUpperCase()}
                </button>
              ))}
              <button
                onClick={closeEditor}
                className="text-gray-500 hover:text-gray-700 text-2xl"
              >
                ×
              </button>
            </div>
          </div>

          {/* Body */}
          <div className="p-4 flex-1 overflow-y-auto">
            {relevantKeys.length === 0 ? (
              <p>Nessuna chiave trovata per questo contesto.</p>
            ) : (
              <table className="w-full text-sm border-collapse">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="p-2 border w-1/4">Key</th>
                    {ALL_LANGS.map((lng) =>
                      !langOpen[lng] ? null : (
                        <th key={lng} className="p-2 border">
                          {lng.toUpperCase()}
                        </th>
                      )
                    )}
                  </tr>
                </thead>
                <tbody>
                  {relevantKeys.map((key) => (
                    <tr key={key} className="border-b">
                      <td className="p-2 border font-bold align-top">{key}</td>
                      {ALL_LANGS.map((lng) => {
                        if (!langOpen[lng]) return null;
                        const val = flatData[lng]?.[key] || "";
                        const isCheckbox = key
                          .toLowerCase()
                          .includes("enabled");
                        // long text if it has "description", "tecnicals", etc.
                        const isLong =
                          val.length > 80 ||
                          key.toLowerCase().includes("description") ||
                          key.toLowerCase().includes("tecnicals") ||
                          key.toLowerCase().includes("features");

                        if (isCheckbox) {
                          return (
                            <td key={lng} className="p-2 border align-top">
                              <input
                                type="checkbox"
                                checked={val.toLowerCase() === "true"}
                                onChange={(e) =>
                                  handleChange(
                                    lng,
                                    key,
                                    e.target.checked ? "true" : "false"
                                  )
                                }
                              />
                            </td>
                          );
                        }

                        return (
                          <td key={lng} className="p-2 border align-top">
                            {isLong ? (
                              <textarea
                                rows={3}
                                className="w-full border"
                                value={val}
                                onChange={(e) =>
                                  handleChange(lng, key, e.target.value)
                                }
                              />
                            ) : (
                              <input
                                type="text"
                                className="w-full border px-1 py-0.5"
                                value={val}
                                onChange={(e) =>
                                  handleChange(lng, key, e.target.value)
                                }
                              />
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>

          {/* Footer */}
          <div className="p-4 border-t flex justify-end">
            <button
              onClick={handleSaveClick}
              className={`bg-blue-600 text-white px-4 py-2 rounded mr-2 ${
                isSaving ? "opacity-50 cursor-not-allowed" : ""
              }`}
              disabled={isSaving}
            >
              {isSaving ? "Salvataggio..." : "Salva"}
            </button>
            <button
              onClick={closeEditor}
              className="bg-gray-500 text-white px-4 py-2 rounded"
            >
              Chiudi
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default TranslationEditor;
