// src/pages/products/CP1N.jsx
import React from "react";
import ProductTemplate from "./ProductTemplate";
import { useTranslation } from "react-i18next";

const CP1N = () => {
  const { t, ready } = useTranslation("global");

  if (!ready) {
    return null; // ProductTemplate will show the loading spinner
  }

  const productData = {
    img1Src: t("cp1n.img1Src"),
    img1Alt: t("cp1n.img1Alt"),
    title: "cp1n.title",
    subtitle: "cp1n.subtitle",
    description: "cp1n.description",
    features: t("cp1n.features", { returnObjects: true }) || [],
    tecnicals: t("cp1n.tecnicals", { returnObjects: true }) || [],
    directives: "cp1n.directives",
    img2Src: t("cp1n.img2Src"),
    img2Alt: t("cp1n.img2Alt"),
    options: t("cp1n.options", { returnObjects: true }) || [],
    warranty: "cp1n.warranty",
    specs: {
      headers: t("cp1n.specs.headers", { returnObjects: true }) || [],
      values: t("cp1n.specs.values", { returnObjects: true }) || [],
    },
    relatedProducts: t("cp1n.relatedProducts", { returnObjects: true }) || [],
  };

  return <ProductTemplate {...productData} />;
};

export default CP1N;
