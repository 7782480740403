import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { routesConfig } from "../routesConfig";

export function SEO({ page }) {
  const { t, i18n } = useTranslation("global");
  const location = useLocation();
  const lang = i18n.language;

  const baseUrl = "https://agerussi.com";
  const currentPath = location.pathname;

  const findPageConfig = () => {
    const currentLangGroup = routesConfig.find((group) => group.lang === lang);
    if (!currentLangGroup) return null;

    let pageConfig = currentLangGroup.routes.find(
      (route) => route.path === currentPath
    );

    if (!pageConfig) {
      // Check in nested routes (sectors, materials, products)
      for (const route of currentLangGroup.routes) {
        if (route.categories) {
          pageConfig = route.categories.find((cat) => cat.path === currentPath);
          if (pageConfig) break;
        }
        if (route.pages) {
          pageConfig = route.pages.find((p) => p.path === currentPath);
          if (pageConfig) break;
        }
      }
    }

    if (!pageConfig) {
      // Check in products
      pageConfig = Object.values(currentLangGroup.products).find(
        (prod) => prod.path === currentPath
      );
    }

    return pageConfig;
  };

  const pageConfig = findPageConfig();
  const seoKey = pageConfig ? pageConfig.key : page;

  const getCanonicalUrl = () => `${baseUrl}${currentPath}`;

  const getAlternateUrls = () => {
    return routesConfig.map((group) => {
      if (pageConfig) {
        const alternatePath =
          group.routes.find((route) => route.key === pageConfig.key)?.path ||
          currentPath;
        return {
          lang: group.lang,
          url: `${baseUrl}${group.basePath}${alternatePath}`,
        };
      }
      return {
        lang: group.lang,
        url: `${baseUrl}${group.basePath}${currentPath}`,
      };
    });
  };

  return (
    <Helmet>
      <html lang={lang} />
      <title>{t(`seo.${seoKey}.title`)}</title>
      <meta name="robots" content="index, follow" />
      <meta name="author" content="Gerussi" />
      <meta name="publisher" content="Gerussi" />
      <meta name="description" content={t(`seo.${seoKey}.description`)} />
      <meta name="keywords" content={t(`seo.${seoKey}.keywords`)} />
      {getAlternateUrls().map((alt) => (
        <link
          key={alt.lang}
          rel="alternate"
          hreflang={alt.lang}
          href={alt.url}
        />
      ))}
      <link rel="canonical" href={getCanonicalUrl()} />
    </Helmet>
  );
}
