import React from "react";
import getPathByKey from "../utils/getPathByKey ";
import LocalizedNavLink from "../utils/LocalizedNavLink";

const FlipImage = ({ imageFront, imageBack, text, to }) => {
  const path = getPathByKey(to);
  return (
    <LocalizedNavLink to={path} className="group block">
      <div className="relative aspect-square bg-black bg-opacity-40 rounded-lg overflow-hidden group">
        <div className="absolute inset-0 transition-transform duration-200 ease-[cubic-bezier(0.34,1.56,0.64,1)] transform group-hover:opacity-0 group-hover:scale-0">
          <div className="flex justify-center items-center h-full">
            <img
              src={imageFront}
              alt="Front Image"
              className="object-contain w-full h-full"
            />
          </div>
        </div>
        <div className="absolute inset-0 transition-transform duration-300 ease-out transform scale-0 group-hover:scale-100">
          <div className="flex justify-center items-center h-full p-10">
            <img
              src={imageBack}
              alt="Back Image"
              className="object-contain w-full h-full"
            />
          </div>
        </div>
      </div>
      <div className="relative inset-0 flex text-center p-2 items-end text-white text:lg leading-snug lg:text-2xl font-light mb-4">
        <h4 className="w-full">{text}</h4>
      </div>
    </LocalizedNavLink>
  );
};

export default FlipImage;
